var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"ministry"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")]),_c('a-row',{staticClass:"flex-no__wrap ml-auto wanted-search-input-wrapper",staticStyle:{"justify-content":"flex-end"},attrs:{"type":"flex"}})],1),_c('a-table',{key:_vm.$route.fullPath,attrs:{"columns":_vm.columns,"data-source":_vm.tableProps},scopedSlots:_vm._u([{key:"name",fn:function(item){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            name: item.name,
            params: {
              id: item.id
            },
            query: { title: item.title }
          })}}},[_vm._v(" "+_vm._s(item.title[_vm.$i18n.locale])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }