<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>

    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: {
                id: item.id
              },
              query: { title: item.title }
            })
          "
        >
          {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

const columns = [
  {
    title: "Название",
    key: "title",
    class: "wanted-fio-td",
    scopedSlots: {
      customRender: "name"
    }
  }
]

export default {
  data: () => ({
    tableProps: [
      {
        title: {
          uz: "Rahbariyat",
          oz: "Раҳбарият",
          ru: "Руководство",
          en: "Management"
        },
        id: 1,
        name: "staffs"
      },
      {
        title: {
          uz: "Islom taraqqiyot banki bilan qo‘shma loyihalar",
          oz: "Ислом тараққиёт банки билан қўшма лойиҳалар",
          ru: "Совместные проекты с Исламским банком развития",
          en: "Joint projects with the Islamic Development Bank"
        },
        id: 600,
        name: "posts"
      }

      // {
      //   title: {
      //     uz: "Xodimlar",
      //     oz: "Ходимлар",
      //     ru: "Cотрудники",
      //     en: "Employees"
      //   },
      //   id: 2,
      //   name: "staffs"
      // },
      // {
      //   title: {
      //     uz: "Ilmiy-texnik kengash",
      //     oz: "Илмий-техник кенгаш",
      //     ru: "Научно-технический совет",
      //     en: "Scientific and technical council"
      //   },
      //   id: 23,
      //   name: "posts"
      // },
      // {
      //   title: {
      //     uz: "Boshqarmalar",
      //     oz: "Бошқармалар",
      //     ru: "Управления",
      //     en: "Departments"
      //   },
      //   id: 3,
      //   name: "department"
      // },
      // {
      //   title: {
      //     uz: "Bo`limlar",
      //     oz: "Бўлимлар",
      //     ru: "Отделы",
      //     en: "Divisions"
      //   },
      //   id: 4,
      //   name: "division"
      // },
      // {
      //   title: {
      //     uz: "Tizimdagi tashkilotlar",
      //     oz: "Тизимдаги ташкилотлар",
      //     ru: "Подведомственные организации",
      //     en: "Organizations in the system"
      //   },
      //   id: 5,
      //   name: "organization"
      // }
    ],
    columns
  }),
  methods: {}
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
